import React from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core"
import { observer } from "mobx-react"
import { useUiStore } from "hooks/stores"
import { minMaxSoundTuningDisplay } from "../../../utils/helpers"

function New_hearing_assessment_dialog({ clearValues }) {
  const {
    closeConfigurationModal,
    closeHearingAssessmentDialog,
    showHearingAssessmentDialog,
    closeHearingAssessmentModal,
  } = useUiStore()

  const handleCloseAndGoBack = () => {
    closeHearingAssessmentDialog()
  }

  const handleClose = () => {
    clearValues()
    closeHearingAssessmentDialog()
    closeHearingAssessmentModal()
    closeConfigurationModal()
  }

  return (
    <>
      <Dialog
        open={showHearingAssessmentDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Threshold and configuration details will not be saved.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAndGoBack} color="primary">
            Go Back
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Exit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default observer(New_hearing_assessment_dialog)
