import React, { useState } from "react"
import {
  AppBar,
  Tabs,
  Tab,
  Drawer,
  ListItem,
  ListItemText,
} from "@material-ui/core"
import styled from "styled-components"
import colors from "styles/colors"
import breakpoint from "styled-components-breakpoint"
import { observer } from "mobx-react"
import { useDevicesStore, useSessionStore, useUiStore } from "hooks/stores"
import System from "./system/system"
import Loading from "components/loading/loading"
import Phone from "./phone/phone"
import SystemDeactivated from "./system/system_deactivated"

const StyledTabPanel = styled.div`
  justify-content: center;
  height: 100%;
  background-color: ${colors.white};
  overflow: auto;
`

const StyledTab = styled(Tab)`
  && {
    text-transform: none;
    color: ${colors.blue};
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
    min-width: 100px;
    margin-right: 16px;

    &:focus {
      opacity: 1;
    }
  }
`

const StyledTabs = styled(Tabs)`
  && {
    background-color: ${colors.white};

    .MuiTabs-indicator {
      background-color: ${colors.blue};
    }
  }
`

const StyledBox = styled.div`
  && {
    padding: 0;
  }
`

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <StyledTabPanel
      hidden={value !== index}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <StyledBox p={3}>{children}</StyledBox>}
    </StyledTabPanel>
  )
}

const StyledDrawer = styled(Drawer)`
  && {
    .MuiDrawer-paper {
      width: 400px;
      height: calc(100vh - 42px);
      top: 42px;
      z-index: 1400;
    }

    ${breakpoint("mobile")`
        .MuiDrawer-paper {
           width: 300px;
        }
     `}
  }
`

const StyledListItemText = styled(ListItemText)`
  && {
    padding-top: 12px;

    & > span {
      font-size: 14px;
      line-height: 24px;
      font-weight: 800;
    }
  }
`

const StyledListItem = styled(ListItem)`
  && {
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      height: 1px;
      width: 90%; /* or 100px */
      border-bottom: 1px solid rgba(51, 51, 51, 0.17);
    }
  }
`

const LeftSection = () => {
  const { showDevicesDrawer, switchDevicesDrawer } = useUiStore()
  const {
    eargoUserSystems,
    eargoUserDeactivatedDevices,
    canFetchOtherData,
  } = useSessionStore()
  const {
    userMobileDevicesList,
    isLoaded: areDevicesLoaded,
  } = useDevicesStore()

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <StyledDrawer
      variant="persistent"
      anchor="left"
      open={showDevicesDrawer}
      onClose={switchDevicesDrawer}
      elevation={0}
    >
      <AppBar position="static">
        <StyledTabs value={value} onChange={handleChange} centered>
          <StyledTab label="Devices" />
        </StyledTabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {!userMobileDevicesList?.length ? (
          areDevicesLoaded ? (
            <StyledListItem>
              <StyledListItemText primary="No recent mobile devices" />
            </StyledListItem>
          ) : (
            <Loading />
          )
        ) : (
          userMobileDevicesList.map((device) => (
            <Phone key={device.id} device={device} />
          ))
        )}
        {!eargoUserSystems?.length ? (
          <Loading />
        ) : (
          eargoUserSystems.map((system, index) => (
            <System key={index} system={system} />
          ))
        )}
        {!!eargoUserDeactivatedDevices.length && (
          <SystemDeactivated
            eargoUserDeactivatedDevices={eargoUserDeactivatedDevices}
          />
        )}
      </TabPanel>
    </StyledDrawer>
  )
}

export default observer(LeftSection)
