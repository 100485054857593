import { flow, getEnv, getRoot, types } from "mobx-state-tree"
import { uniqWith } from "lodash"
import MobileDevice from "models/mobile_device/mobile_device"
import { compareVersions } from "utils/helpers"

const INITIAL = "initial"
const LOADING = "loading"
const LOADED = "loaded"
const NOT_FOUND_ERROR = "not_found"
const GET_MOBILE_DEVICES_API_PATH = `/v1/user/mobile_device`
const POST_DIAGNOSTIC_REPORT_PN_PATH = `/v1/diagnostic_report/send_notification`
const POST_APP_RATING_REQ_PN_PATH = `/v1/app_rating_request/send_notification`

const SessionStore = types
  .model("DevicesStore", {
    state: types.optional(
      types.enumeration([INITIAL, LOADING, LOADED, NOT_FOUND_ERROR]),
      INITIAL
    ),
    userMobileDevices: types.optional(types.map(MobileDevice), {}),
  })
  .views((self) => {
    return {
      get isLoaded() {
        return self.state === LOADED
      },
      get isInitial() {
        return self.state === INITIAL
      },
      get isNotFoundError() {
        return self.state === NOT_FOUND_ERROR
      },
      get userMobileDevicesList() {
        const devicesList = Array.from(self.userMobileDevices.values())
        const uniqueDevices = uniqWith(
          devicesList,
          (deviceA, deviceB) =>
            deviceA.phoneModel === deviceB.phoneModel &&
            deviceA.operatingSystem === deviceB.operatingSystem &&
            deviceA.appVersion === deviceB.appVersion &&
            deviceA.id !== deviceB.id
        )
        return uniqueDevices.slice().sort((a, b) => {
          return new Date(b.updatedAt) - new Date(a.updatedAt)
        })
      },
      get getIOSDevices() {
        return self.userMobileDevicesList.filter(
          (mobiles) => mobiles.operatingSystem.split("_")[0] === "iOS"
        )
      },
      get getAndroidDevices() {
        return self.userMobileDevicesList.filter(
          (mobiles) => mobiles.operatingSystem.split(" ")[0] === "Android"
        )
      },
      isVersionForNewConfigAndroid(minAndroidVersion) {
        return !!self.getAndroidDevices?.filter(
          (mobiles) =>
            compareVersions(mobiles.appVersion, minAndroidVersion) >= 0
        ).length
      },
      isVersionForNewConfigIOS(minIosVersion) {
        return !!self.getIOSDevices?.filter(
          (mobiles) => compareVersions(mobiles.appVersion, minIosVersion) >= 0
        ).length
      },
      get isNewConfig() {
        if (
          self.getIOSDevices.length === 0 &&
          self.getAndroidDevices.length === 0
        ) {
          return true
        }
        return (
          self.isVersionForNewConfigAndroid("1.2") ||
          self.isVersionForNewConfigIOS("1.2")
        )
      },
      get isEcaEnabledDevice() {
        return (
          process.env.REACT_APP_EARGO_VERSION === "EARGO_SIX" &&
          getRoot(self).sessionStore.canFetchOtherData
        )
      },
    }
  })
  .actions((self) => {
    const { apiClient } = getEnv(self)

    return {
      startLoading() {
        self.state = LOADING
      },
      endLoading() {
        self.state = LOADED
      },
      setNotFoundError() {
        self.state = NOT_FOUND_ERROR
      },
      fetchDevices: flow(function* fetchDevices() {
        const userId = getRoot(self).sessionStore?.userId
        if (!userId) return
        self.startLoading()
        yield apiClient.requestManager(
          () => apiClient.get(`${GET_MOBILE_DEVICES_API_PATH}`),
          (response) => {
            response.data.userDevices.forEach(self.addMobileDevice)
            self.endLoading()
          },
          (e) => {
            getRoot(self).uiStore.openNotification(`${e}`, "error")
            self.setNotFoundError()
          }
        )
        self.endLoading()
      }),
      sendDiagnosticPN: flow(function* sendDiagnosticPN() {
        const userId = getRoot(self).sessionStore?.userId
        if (!userId) return

        yield apiClient.requestManager(
          () => apiClient.post(`${POST_DIAGNOSTIC_REPORT_PN_PATH}`),
          () => {
            getRoot(self).uiStore.openNotification(
              "Notifying user about Get Serial Number tool",
              "success"
            )
          },
          (e) => {
            getRoot(self).uiStore.openNotification(
              `Error notifying user. ${e.errorResponse?.data?.message} Try again or submit case to SW.`,
              "error"
            )
          }
        )
      }),
      sendAppRatingRequestPN: flow(function* sendAppRatingRequestPN() {
        const userId = getRoot(self).sessionStore?.userId
        if (!userId) return

        yield apiClient.requestManager(
          () => apiClient.post(`${POST_APP_RATING_REQ_PN_PATH}`),
          () => {
            getRoot(self).uiStore.openNotification(
              "Notifying user about App rating request.",
              "success"
            )
          },
          (e) => {
            getRoot(self).uiStore.openNotification(
              `Error notifying user. ${e.errorResponse?.data?.message} Try again or submit case to SW.`,
              "error"
            )
          }
        )
      }),
      sendDiagnosticPN: flow(function* sendDiagnosticPN() {
        const userId = getRoot(self).sessionStore?.userId
        if (!userId) return

        yield apiClient.requestManager(
          () => apiClient.post(`${POST_DIAGNOSTIC_REPORT_PN_PATH}`),
          () => {
            getRoot(self).uiStore.openNotification(
              "Notifying user about Get Serial Number tool",
              "success"
            )
          },
          (e) => {
            getRoot(self).uiStore.openNotification(
              `Error notifying user. ${e.errorResponse?.data?.message} Try again or submit case to SW.`,
              "error"
            )
          }
        )
      }),
      addMobileDevice(attributes) {
        self.userMobileDevices.set(attributes.id, { ...attributes })
      },
      reset() {
        if (self.userMobileDevices.size) {
          self.state = INITIAL
          self.userMobileDevices.clear()
        }
      },
    }
  })

export default SessionStore
