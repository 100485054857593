import { types, getRoot } from "mobx-state-tree"
import { io } from "socket.io-client"

const SocketStore = types
  .model("SocketStore", {})
  .volatile(() => ({
    socket: undefined,
  }))
  .views((self) => {
    return {}
  })
  .actions((self) => {
    return {
      initializeSocket() {
        const userId = getRoot(self).sessionStore?.userId
        if (userId) {
          self.socket = io(process.env.REACT_APP_API_SOCKET, {
            reconnectionAttempts: 5,
            reconnectionDelay: 3000,
            extraHeaders: {
              Authorization: `Basic ${process.env.REACT_APP_API_TOKEN}`,
            },
            query: {
              user_id: userId,
            },
          })

          self.socket.on("reconnect_failed", () => {
            getRoot(self).uiStore.openNotification(
              "cannot connect to websocket",
              "error"
            )
          })
        }
      },
    }
  })

export default SocketStore
