import defaultAxios from "axios"
import qs from "qs"
import { mapKeysToCamelCase, mapKeysToSnakeCase } from "utils/helpers"

const NOT_FOUND = "404"

class ApiClient {
  errors = {
    NOT_FOUND: { type: NOT_FOUND },
  }

  constructor({ apiToken, apiUrl, headers = {} }) {
    this.axios = defaultAxios
    this.apiToken = apiToken
    this.headers = headers
    this.settings = {
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "brackets" }),
      baseURL: apiUrl,
    }
    this.userId = null
  }

  setUserId(id) {
    this.userId = id
  }

  async requestManager(request, onSuccessCallback, onErrorCallback) {
    try {
      const response = await request()
      onSuccessCallback(response)
    } catch (e) {
      onErrorCallback(e)
    }
  }

  async get(endpoint, params = {}, opts = {}) {
    return await this.send(
      Object.assign({ method: "get", endpoint: endpoint, params: params }, opts)
    )
  }

  async post(endpoint, payload = {}, opts = {}) {
    return await this.send(
      Object.assign(
        { method: "post", endpoint: endpoint, payload: payload },
        opts
      )
    )
  }

  async put(endpoint, payload = {}, opts = {}) {
    return await this.send(
      Object.assign(
        { method: "put", endpoint: endpoint, payload: payload },
        opts
      )
    )
  }

  async patch(endpoint, payload = {}, opts = {}) {
    return await this.send(
      Object.assign(
        { method: "patch", endpoint: endpoint, payload: payload },
        opts
      )
    )
  }
  async delete(endpoint, payload = {}, opts = {}) {
    return await this.send(
      Object.assign(
        { method: "delete", endpoint: endpoint, payload: payload },
        opts
      )
    )
  }

  async send(request) {
    const {
      method = "get",
      endpoint,
      payload = {},
      headers = {},
      responseType = "json",
      params,
    } = request

    Object.assign(headers, this.headers)

    headers["Authorization"] = `Basic ${this.apiToken}`
    headers["user-id"] = this.userId

    try {
      const response = await this.axios({
        method,
        headers,
        params,
        responseType,
        url: endpoint,
        data: mapKeysToSnakeCase(payload),
        ...this.settings,
      })

      return mapKeysToCamelCase(response.data)
    } catch (error) {
      if (error.response && error.response.status === 404) {
        throw { ...this.errors.NOT_FOUND, errorResponse: error.response }
      } else {
        throw { errorResponse: error.response }
      }
    }
  }
}

export default ApiClient
