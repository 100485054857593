import { flow, getEnv, getRoot, types } from "mobx-state-tree"
import Log from "models/log/log"
import { changeValuesInProfiles, mapKeysToCamelCase } from "../../utils/helpers"
import { compareDesc, isAfter, isBefore, isSameDay, parseISO } from "date-fns"
import { sortBy } from "lodash"

const INITIAL = "initial"
const LOADING = "loading"
const LOADED = "loaded"
const NOT_FOUND_ERROR = "not_found"
const GET_EVENTS_API_PATH = `/v1/php/events`

const LogsStore = types
  .model("LogsStore", {
    state: types.optional(
      types.enumeration([INITIAL, LOADING, LOADED, NOT_FOUND_ERROR]),
      INITIAL
    ),
    logs: types.optional(types.map(Log), {}),
    currentLogTitle: types.maybeNull(types.string),
    currentLogDate: types.maybeNull(types.string),
    startDate: types.maybeNull(types.Date),
    endDate: types.maybeNull(types.Date),
  })
  .views((self) => {
    return {
      get isLoaded() {
        return self.state === LOADED
      },
      get isLoading() {
        return self.state === LOADING
      },
      get isInitial() {
        return self.state === INITIAL
      },
      get isNotFoundError() {
        return self.state === NOT_FOUND_ERROR
      },
      get logsArray() {
        return Array.from(self.logs.values())
          .slice()
          .sort(function (a, b) {
            a.createdAt ? (a = parseISO(a.createdAt)) : (a = 0)
            b.createdAt ? (b = parseISO(b.createdAt)) : (b = 0)
            return compareDesc(a, b)
          })
      },
      get lastLogWithStatus() {
        const filterLogs = self.logsArray.filter(
          (log) =>
            log.status &&
            (log.status === "RECEIVED" ||
              log.status === "SENDING" ||
              log.status === "APPLIED")
        )
        if (filterLogs[0]?.status !== "APPLIED") {
          return filterLogs[0]
        } else {
          return null
        }
      },
      get logsList() {
        if (self.startDate && self.endDate) {
          return self.dateRangeLogsList(self.startDate, self.endDate)
        } else if (self.startDate) {
          return self.singleDayLogsList(self.startDate)
        } else if (self.endLoading) {
          return self.logsArray
        } else {
          return null
        }
      },
      dateRangeLogsList(startDate, endDate) {
        return self.logsArray.filter((log) => {
          return (
            isSameDay(new Date(log.createdAt), new Date(startDate)) ||
            isSameDay(new Date(log.createdAt), new Date(endDate)) ||
            (isAfter(new Date(log.createdAt), new Date(startDate)) &&
              isBefore(new Date(log.createdAt), new Date(endDate)))
          )
        })
      },
      singleDayLogsList(startDate) {
        return self.logsArray.filter((log) => {
          return isSameDay(new Date(log.createdAt), new Date(startDate))
        })
      },
      get minDate() {
        return new Date(self.logsArray[self.logsArray.length - 1]?.createdAt)
      },
    }
  })
  .actions((self) => {
    const { apiClient } = getEnv(self)

    return {
      startLoading() {
        self.state = LOADING
      },
      endLoading() {
        self.state = LOADED
      },
      setNotFoundError() {
        self.state = NOT_FOUND_ERROR
      },
      setCurrentLogTitle(value) {
        self.currentLogTitle = value
      },
      setCurrentLogDate(value) {
        self.currentLogDate = value
      },
      initSocketLogs() {
        const { socket } = getRoot(self).socketStore

        const fetchConfigAndHearingAssessment = () => {
          getRoot(self).configurationStore.reset()
          getRoot(self).configurationStore.fetchConfiguration()
          getRoot(self).hearingEvaluationStore.reset()
          getRoot(self).hearingEvaluationStore.fetchHearingEvaluation()
        }

        socket.onAny((eventName, response) => {
          switch (eventName) {
            case "newDbConfiguration":
            case "newHearingAssessment":
              self.addLog(mapKeysToCamelCase(response))
              fetchConfigAndHearingAssessment()
              break
            case "updateHearingAssessment":
              fetchConfigAndHearingAssessment()
              break
            case "newPhpConfiguration":
            case "newTutorialNotification":
            case "updatePhpEvent":
            case "firmwareUpdate":
            case "initialPairing":
            case "diagnosticReport":
              self.addLog(mapKeysToCamelCase(response))
              break
            default:
              return null
          }
        })
      },
      stopSocketLogs() {
        const { socket } = getRoot(self).socketStore
        socket.offAny()
      },
      fetchLogs: flow(function* fetch() {
        const userId = getRoot(self).sessionStore?.userId
        if (!userId) return
        self.startLoading()
        yield apiClient.requestManager(
          () => apiClient.get(`${GET_EVENTS_API_PATH}`),
          (response) => {
            response.data.phpEvents.forEach((event) => {
              self.addLog(event)
            })
            self.endLoading()
          },
          (e) => {
            getRoot(self).uiStore.openNotification(
              `${e}`,
              "error"
            )
            self.setNotFoundError()
          }
        )
      }),
      addLog({ id, type, payload, createdAt, message, status }) {
        if (id) {
          if (payload !== {}) {
            payload.hearingProfiles = changeValuesInProfiles(
              sortBy(payload.hearingProfiles, "position"),
              "fetch"
            )
            if (payload.favourites) {
              payload.favourites = changeValuesInProfiles(
                payload.favourites,
                "fetch"
              )
            }
            if (payload.hearingAssessment) {
              payload.hearingAssessment.id = `${payload.hearingAssessment.id}`
            }
          }

          self.logs.set(String(id), {
            id: String(id),
            type,
            payload,
            createdAt,
            message,
            status,
          })
        }
      },
      setStartDate(value) {
        self.startDate = value
      },
      setEndDate(value) {
        self.endDate = value
      },
      clearLogsValues() {
        self.setCurrentLogTitle(null)
        self.setCurrentLogDate(null)
      },
      reset() {
        self.state = INITIAL
        self.logs.clear()
      },
    }
  })

export default LogsStore
