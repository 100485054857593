import React, { useCallback, useEffect, useState } from "react"
import { observer } from "mobx-react"
import styled from "styled-components"
import colors from "styles/colors"
import { Grid, useScrollTrigger, AppBar, Toolbar } from "@material-ui/core"
import {
  useSessionStore,
  useConfigurationStore,
  useSocketStore,
  useJournalStore,
  useLogsStore,
  useStatisticsStore,
  useDevicesStore,
  useHearingEvaluationStore,
  useUiStore,
} from "hooks/stores"
import SelectUser from "components/select_user/select_user"
import ContextMenu from "./context_menu/context_menu"
import { eargoVersionDisplay } from "utils/helpers"

const Wrapper = styled.div`
  padding: 0 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  transition: height 0.3s;
`

const StyledAppBar = styled(AppBar)`
  && {
    .MuiToolbar-regular {
      min-height: 42px;
    }

    background-color: ${colors.blue};
    font-weight: bold;
    font-size: 18px;
    line-height: 40px;
  }
`

const AppVersionWrapper = styled.div`
  height: 48px;
  line-height: 58px;
  display: flex;
  justify-content: center;
`

const StyledToolbar = styled(Toolbar)`
  && {
    padding-left: 0;
    padding-right: 0;
  }
`

const HelpLink = styled.div`
  display: flex;
  justify-content: center;
  color: black;
  height: 48px;
  line-height: 58px;
`

const StyledLink = styled.a`
  color: white;
  text-decoration: none;
  font-weight: 800;
  font-size: 14px;
  height: 48px;
`

const UserSelection = styled.a`
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 44px;
  height: 44px;
  display: inline-block;
  justify-content: center;
`

function ElevationScroll({ children, trigger }) {
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

const DefaultNavbar = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 12,
  })

  const [customerData, setCustomerData] = useState({
    firstName: "",
    lastName: "",
  })

  useEffect(() => {
    const url = new URL(window.location.href)
    const firstName = url.searchParams.get("firstName")
    const lastName = url.searchParams.get("lastName")
    setCustomerData({ firstName, lastName })
  }, [])

  // TODO unmock from sessionStore
  return (
    <ElevationScroll trigger={trigger}>
      <StyledAppBar elevation={10} position="static">
        <StyledToolbar>
          <Wrapper>
            <Grid container>
              <Grid item sm={4}>
                <ContextMenu />
                {process.env.REACT_APP_DEBUG_MODE === "true" ? (
                  <>
                    <SelectUser />
                  </>
                ) : (
                  <UserSelection>
                    {customerData.firstName} {customerData.lastName}
                  </UserSelection>
                )}
              </Grid>
              <Grid item sm={4}>
                <AppVersionWrapper>{eargoVersionDisplay()}</AppVersionWrapper>
              </Grid>
              <Grid item sm={4}>
                <HelpLink>
                  <StyledLink
                    href="https://app.getguru.com/boards/cR6z5Gai/Web-App-Help"
                    target="_blank"
                  >
                    HELP
                  </StyledLink>
                </HelpLink>
              </Grid>
            </Grid>
          </Wrapper>
        </StyledToolbar>
      </StyledAppBar>
    </ElevationScroll>
  )
}

export default observer(DefaultNavbar)
