import { flow, getEnv, getRoot, types } from "mobx-state-tree"
import DeviceConfiguration from "models/device_configuration/device_configuration"
import {
  changeValuesInProfiles,
  mapKeysToCamelCase,
  minMaxSoundTuningDisplay,
} from "utils/helpers"
import { isEmpty, omit, sortBy } from "lodash"
import {
  checkForChangesInFavorites,
  checkForChangesInProfiles,
  checkForUniquePrograms,
  checkIfAllDisabled,
  checkIfRemovedFavourites,
  handleSelectInputs,
  handleSelectInputsOld,
} from "components/middle_section/programs/programs_helpers"
import { toJS } from "mobx"
import selectInputsJson from "utils/selectInputs.json"
import {
  DEFAULT_CONFIG,
  TEMP_FAVOURITES_MOCK,
} from "./configuration_mock_objects"

const INITIAL = "initial"
const LOADING = "loading"
const LOADED = "loaded"
const SENDING = "sending"
const SENT = "sent"
const NOT_FOUND_ERROR = "not_found"
const GET_CONFIG_API_PATH = `/v1/configuration`
const POST_CONFIG_API_PATH_PHP = `/v1/php/configuration`
const POST_HEARING_ASSESSMENT_PATH_PHP = `/v1/configuration/hearing_assessment`

const ConfigurationStore = types
  .model("ConfigurationStore", {
    state: types.optional(
      types.enumeration([INITIAL, LOADING, LOADED, NOT_FOUND_ERROR]),
      INITIAL
    ),
    sendingState: types.optional(
      types.enumeration([INITIAL, SENDING, SENT]),
      INITIAL
    ),
    deviceConfiguration: types.optional(DeviceConfiguration, {}),
    newDeviceConfiguration: types.optional(types.frozen(), {
      hearingProfiles: DEFAULT_CONFIG.device_configuration.hearing_profiles,
      favourites: TEMP_FAVOURITES_MOCK,
    }),
    historyDeviceConfiguration: types.maybeNull(
      types.optional(types.frozen(), null)
    ),
    defaultConfig: types.optional(types.boolean, false),
    isProgramChanged: types.maybeNull(types.optional(types.boolean, false)),
    applyForReset: types.maybeNull(types.optional(types.boolean, false)),
  })
  .volatile(() => ({
    selectInputs: selectInputsJson,
  }))
  .views((self) => {
    return {
      get isSending() {
        return self.sendingState === SENDING
      },
      get isSent() {
        return self.sendingState === SENT
      },
      get isLoaded() {
        return self.state === LOADED
      },
      get isDefault() {
        return self.defaultConfig === true
      },
      get isInitial() {
        return self.state === INITIAL
      },
      get emptyDefaultConfig() {
        return DEFAULT_CONFIG
      },
      get isDefaultConfig() {
        return !self.deviceConfiguration.hearingAssessment
      },
      get isMaskAdded() {
        return self.selectInputs[0].some((input) => input.value === "MASK")
      },
      get isHearingAssessmentResInTempConfig() {
        return self.newDeviceConfiguration?.hearingProfiles.some(
          (hearingProfile) => hearingProfile.isHearingAssessmentRes === true
        )
      },
      get profilesInNewConfiguration() {
        if (!self.newDeviceConfiguration.hearingProfiles.length) {
          return []
        } else {
          const actualOffsets = []

          self.newDeviceConfiguration.hearingProfiles.forEach((profile) => {
            if (
              profile.environmentalOffset !== "NONE" &&
              actualOffsets.length <= 3
            ) {
              actualOffsets.push(profile.environmentalOffset)
            }
          })
          return [...new Set(actualOffsets)]
        }
      },
      get areProfilesChanged() {
        return checkForChangesInProfiles(
          self.deviceConfiguration.plainDeviceConfiguration,
          self.newDeviceConfiguration
        )
      },
      get isFavoriteChanged() {
        return checkForChangesInFavorites(
          self.deviceConfiguration.plainDeviceConfiguration,
          self.newDeviceConfiguration
        )
      },
      get areAllProgramsDisabled() {
        return checkIfAllDisabled(self.newDeviceConfiguration)
      },
      get areAllProgramsUnique() {
        return checkForUniquePrograms(self.newDeviceConfiguration)
      },
      get areFavoritesRemoved() {
        return checkIfRemovedFavourites(
          self.deviceConfiguration.plainDeviceConfiguration,
          self.newDeviceConfiguration
        )
      },
      get checkIfBaselineIsSelectedForBoth() {
        return (
          (!self.newDeviceConfiguration.favouriteLeftSlot &&
            self.newDeviceConfiguration.favouriteRightSlot) ||
          (self.newDeviceConfiguration.favouriteLeftSlot &&
            !self.newDeviceConfiguration.favouriteRightSlot)
        )
      },
      get checkIfNormalProgramIsPresentWhenSoundMatchApplied() {
        const { tempHearingAssessment } = getRoot(self).hearingEvaluationStore
        return (
          tempHearingAssessment?.failed === false &&
          tempHearingAssessment?.active === true &&
          !self.isHearingAssessmentResInTempConfig &&
          !self.applyForReset
        )
      },
      get checkIfSMisPresentWhenWeHaveNormalProgram() {
        const { tempHearingAssessment } = getRoot(self).hearingEvaluationStore

        return (
          !tempHearingAssessment &&
          self.isHearingAssessmentResInTempConfig &&
          !self.applyForReset
        )
      },
      get checkIfSMIsNewOrChanged() {
        const { tempHearingAssessment, isNewSoundMatch } = getRoot(
          self
        ).hearingEvaluationStore
        return tempHearingAssessment || isNewSoundMatch
      },
      checkIfIsPresetOfIndex(index) {
        if (
          !!self.newDeviceConfiguration?.hearingProfiles[index]
            .environmentalOffset
        ) {
          return (
            self.newDeviceConfiguration?.hearingProfiles[index]
              .environmentalOffset === "NONE"
          )
        }
      },
      checkIfShowNormalOnBaselineSelect(index) {
        return (
          (!self.checkIfIsPresetOfIndex(index) &&
            self.isHearingAssessmentResInTempConfig) ||
          self.newDeviceConfiguration?.hearingProfiles[index]
            .isHearingAssessmentRes
        )
      },
      tempFavouriteRightSlot(configuration) {
        if (configuration.favouriteRightIndex && configuration.favourites) {
          return configuration.hearingProfiles.find((profile) => {
            return (
              profile.baseProgramLeft ===
                configuration.favourites[configuration.favouriteRightIndex]
                  ?.program &&
              profile.baseProgramRight ===
                configuration.favourites[configuration.favouriteRightIndex]
                  ?.program &&
              profile.environmentalOffset === "NONE"
            )
          })?.position
        } else {
          return configuration.favouriteRightSlot
        }
      },
      tempFavouriteLeftSlot(configuration) {
        if (configuration.favouriteRightIndex && configuration.favourites) {
          return configuration.hearingProfiles.find((profile) => {
            return (
              profile.baseProgramLeft ===
                configuration.favourites[configuration.favouriteLeftIndex]
                  ?.program &&
              profile.baseProgramRight ===
                configuration.favourites[configuration.favouriteLeftIndex]
                  ?.program &&
              profile.environmentalOffset === "NONE"
            )
          })?.position
        } else {
          return configuration.favouriteLeftSlot
        }
      },
      get isMaksEnv() {
        const { isVersionForMaskEnv } = getRoot(self).sessionStore
        const {
          isVersionForNewConfigIOS,
          isVersionForNewConfigAndroid,
        } = getRoot(self).devicesStore

        return (
          isVersionForMaskEnv("3.0.0.133") &&
          (isVersionForNewConfigIOS("1.3") ||
            isVersionForNewConfigAndroid("1.3"))
        )
      },
    }
  })
  .actions((self) => {
    const { apiClient } = getEnv(self)

    return {
      startSending() {
        self.sendingState = SENDING
      },
      endSending() {
        self.sendingState = SENT
      },
      startLoading() {
        self.state = LOADING
      },
      endLoading() {
        self.state = LOADED
      },
      setNotFoundError() {
        self.state = NOT_FOUND_ERROR
      },
      setDefaultConfig(value) {
        self.defaultConfig = value
      },

      setCheckHandleSelectInputs(obj, isDefault) {
        const { isNewConfig } = getRoot(self).devicesStore
        isNewConfig
          ? handleSelectInputs(self.selectInputs, obj, isDefault)
          : handleSelectInputsOld(self.selectInputs, obj, isDefault)
      },
      setEnvOffsetsInNewHearingAssessment() {
        const { newHearingAssessment, setNewHearingAssessment } = getRoot(
          self
        ).hearingEvaluationStore

        const newObj = { ...newHearingAssessment }
        newObj.environmentalOffsets = self.profilesInNewConfiguration
        setNewHearingAssessment(newObj)
      },
      applyFavoritesValuesToProfile(tempNewDeviceConfiguration, value, index) {
        const indexWhileNormalPresent = self.isHearingAssessmentResInTempConfig
          ? 0
          : index
        const {
          baseProgramLeft: defaultBaseProgramLeft,
          baseProgramRight: defaultBaseProgramRight,
        } =
          tempNewDeviceConfiguration.hearingProfiles[indexWhileNormalPresent] ||
          {}

        const {
          program: favoriteLeftProgram,
          soundTuningLeftVolume: favoriteSoundTuningLeftVolume,
          soundTuningLeftBass: favoriteSoundTuningLeftBass,
          soundTuningLeftTreble: favoriteSoundTuningLeftTreble,
          isLeftFeedbackEnabled: favoriteIsLeftFeedbackEnabled,
        } =
          tempNewDeviceConfiguration.favourites[
            tempNewDeviceConfiguration.favouriteLeftIndex
          ] || {}

        const {
          program: favoriteRightProgram,
          soundTuningRightVolume: favoriteSoundTuningRightVolume,
          soundTuningRightBass: favoriteSoundTuningRightBass,
          soundTuningRightTreble: favoriteSoundTuningRightTreble,
          isRightFeedbackEnabled: favoriteIsRightFeedbackEnabled,
        } =
          tempNewDeviceConfiguration.favourites[
            tempNewDeviceConfiguration.favouriteRightIndex
          ] || {}

        const {
          DEFAULT_VOLUME_VALUE,
          DEFAULT_BASS_VALUE,
          DEFAULT_TREBLE_VALUE,
          DEFAULT_FEEDBACK_ENABLED_VALUE,
        } = minMaxSoundTuningDisplay

        if (value) {
          Object.assign(tempNewDeviceConfiguration.hearingProfiles[index], {
            environmentalOffset: String(value),
          })
        }

        Object.assign(tempNewDeviceConfiguration.hearingProfiles[index], {
          baseProgramLeft: favoriteLeftProgram || defaultBaseProgramLeft,
          baseProgramRight: favoriteRightProgram || defaultBaseProgramRight,
          soundTuningLeftVolume:
            favoriteSoundTuningLeftVolume || DEFAULT_VOLUME_VALUE,
          soundTuningRightVolume:
            favoriteSoundTuningRightVolume || DEFAULT_VOLUME_VALUE,
          soundTuningLeftBass:
            favoriteSoundTuningLeftBass || DEFAULT_BASS_VALUE,
          soundTuningRightBass:
            favoriteSoundTuningRightBass || DEFAULT_BASS_VALUE,
          soundTuningLeftTreble:
            favoriteSoundTuningLeftTreble || DEFAULT_TREBLE_VALUE,
          soundTuningRightTreble:
            favoriteSoundTuningRightTreble || DEFAULT_TREBLE_VALUE,
          isLeftFeedbackEnabled:
            favoriteIsLeftFeedbackEnabled || DEFAULT_FEEDBACK_ENABLED_VALUE,
          isRightFeedbackEnabled:
            favoriteIsRightFeedbackEnabled || DEFAULT_FEEDBACK_ENABLED_VALUE,
        })
      },
      applyForNewConfiguration(tempNewDeviceConfiguration) {
        const { isNewConfig } = getRoot(self).devicesStore
        if (isNewConfig) {
          Object.assign(tempNewDeviceConfiguration, {
            favouriteRightSlot: self.tempFavouriteRightSlot(
              tempNewDeviceConfiguration
            ),
            favouriteLeftSlot: self.tempFavouriteLeftSlot(
              tempNewDeviceConfiguration
            ),
          })
        }
      },
      firstApplyForNewSoundMatch(tempNewDeviceConfiguration) {
        const { isNewSoundMatch } = getRoot(self).hearingEvaluationStore
        if (isNewSoundMatch) {
          Object.assign(tempNewDeviceConfiguration.hearingProfiles[0], {
            isLeftFeedbackEnabled: false,
            isRightFeedbackEnabled: false,
          })
        }
      },
      applyForNewSoundMatch() {
        const { isNewSoundMatch } = getRoot(self).hearingEvaluationStore
        if (isNewSoundMatch) {
          self.setEnvOffsetsInNewHearingAssessment()
        }
      },
      applyForChangesInProfiles(newObj) {
        const changesInProfiles = checkForChangesInProfiles(
          self.deviceConfiguration.plainDeviceConfiguration,
          newObj
        )

        changesInProfiles
          ? self.setIsProgramChanged(true)
          : self.setIsProgramChanged(false)
      },
      setHistoryDeviceConfiguration(obj) {
        const {
          getHearingEvaluationIndexById,
          setTempHearingAssessment,
          setEnabledConfigurationOfIndex,
          clearHearingEvaluationValues,
        } = getRoot(self).hearingEvaluationStore
        const { isNewConfig } = getRoot(self).devicesStore
        let findSlotOfFavouritesLeft,
          findSlotOfFavouritesRight = null
        if (
          obj.favourites &&
          obj.favouriteLeftIndex &&
          obj.favouriteRightIndex &&
          isNewConfig
        ) {
          findSlotOfFavouritesLeft = obj.hearingProfiles.find(
            (profile) =>
              profile.baseProgramLeft ===
                obj.favourites[obj.favouriteLeftIndex].program &&
              profile.baseProgramRight ===
                obj.favourites[obj.favouriteLeftIndex].program
          )?.position
          findSlotOfFavouritesRight = obj.hearingProfiles.find(
            (profile) =>
              profile.baseProgramLeft ===
                obj.favourites[obj.favouriteRightIndex].program &&
              profile.baseProgramRight ===
                obj.favourites[obj.favouriteRightIndex].program
          )?.position
        }

        const hearingEvaluationIndex = getHearingEvaluationIndexById(
          obj?.hearingAssessment?.id
        )
        if (hearingEvaluationIndex !== -1) {
          setEnabledConfigurationOfIndex(hearingEvaluationIndex)
        } else {
          clearHearingEvaluationValues()
        }
        if (
          (self.newDeviceConfiguration.hearingAssessment === null ||
            typeof self.newDeviceConfiguration.hearingAssessment ===
              "undefined") &&
          obj.hearingProfiles.some((profile) => profile.isHearingAssessmentRes)
        ) {
          setTempHearingAssessment(obj?.hearingAssessment)
        }

        self.historyDeviceConfiguration = {
          ...obj,
          favouriteRightSlot: isNewConfig
            ? findSlotOfFavouritesRight
            : obj.favouriteRightSlot,
          favouriteLeftSlot: isNewConfig
            ? findSlotOfFavouritesLeft
            : obj.favouriteLeftSlot,
          favourites: obj.favourites || TEMP_FAVOURITES_MOCK.favourites,
          hearingProfiles: sortBy(obj.hearingProfiles, "position"),
        }
      },
      clearHistoryDeviceConfiguration() {
        self.historyDeviceConfiguration = null
      },
      setIsProgramChanged(value) {
        self.isProgramChanged = value
      },
      setApplyForReset(value) {
        self.applyForReset = value
      },
      setNewDeviceConfiguration(configuration) {
        self.newDeviceConfiguration = configuration
      },
      applyValuesForFavourites(obj, value, key, side, index) {
        const isFeedback =
          key === "isRightFeedbackEnabled" || key === "isLeftFeedbackEnabled"
        const isECA = key === "isEcaEnabled"

        const currentEnv =
          side === "left"
            ? obj.hearingProfiles[index].baseProgramLeft
            : obj.hearingProfiles[index].baseProgramRight

        const favouriteIndex = [
          "PROGRAM_A",
          "PROGRAM_B",
          "PROGRAM_C",
          "PROGRAM_D",
        ].indexOf(currentEnv)

        if (
          self.isHearingAssessmentResInTempConfig &&
          obj.hearingProfiles[index].isHearingAssessmentRes
        ) {
          obj.favourites[0][key] =
            isFeedback || isECA ? Boolean(value) : parseInt(value)
          return obj
        }

        if (
          favouriteIndex !== -1 &&
          obj.hearingProfiles[index].environmentalOffset === "NONE"
        ) {
          obj.favourites[favouriteIndex + 1][key] = isFeedback
            ? Boolean(value)
            : parseInt(value)
        }

        return obj
      },
      setValueWithKey(key, index, value) {
        const newObj = { ...self.newDeviceConfiguration }
        newObj.hearingProfiles[index][key] = value
        return self.setNewDeviceConfiguration(newObj)
      },
      setValueToInputsAndSelects(key, index, side, type, value) {
        const newObj = { ...self.newDeviceConfiguration }

        if (typeof value === "boolean") {
          newObj.hearingProfiles[index][key] = Boolean(value)
        } else if (typeof value === "string" && type === "number") {
          newObj.hearingProfiles[index][key] = parseInt(value)
          const appliedObj = self.applyValuesForFavourites(
            newObj,
            value,
            key,
            side,
            index
          )
          return self.setNewDeviceConfiguration(appliedObj)
        } else if (typeof value === "string") {
          newObj.hearingProfiles[index][key] = value
        }

        self.setNewDeviceConfiguration(newObj)
      },
      setBaseProgram(key, index, side, value) {
        const newObj = { ...self.newDeviceConfiguration }
        newObj.hearingProfiles[index][key] = value
        const baselineFavorite = newObj.favourites.filter(
          (favourite) =>
            favourite.program === value && !favourite.isHearingAssessmentRes
        )[0]
        switch (side) {
          case "left":
            Object.assign(newObj.hearingProfiles[index], {
              soundTuningLeftVolume:
                baselineFavorite?.soundTuningLeftVolume ||
                minMaxSoundTuningDisplay.DEFAULT_VOLUME_VALUE,
              soundTuningLeftBass:
                baselineFavorite?.soundTuningLeftBass ||
                minMaxSoundTuningDisplay.DEFAULT_BASS_VALUE,
              soundTuningLeftTreble:
                baselineFavorite?.soundTuningLeftTreble ||
                minMaxSoundTuningDisplay.DEFAULT_TREBLE_VALUE,
              isLeftFeedbackEnabled:
                baselineFavorite?.isLeftFeedbackEnabled ||
                minMaxSoundTuningDisplay.DEFAULT_FEEDBACK_ENABLED_VALUE,
            })
            break
          case "right":
            Object.assign(newObj.hearingProfiles[index], {
              soundTuningRightVolume:
                baselineFavorite?.soundTuningRightVolume ||
                minMaxSoundTuningDisplay.DEFAULT_VOLUME_VALUE,
              soundTuningRightBass:
                baselineFavorite?.soundTuningRightBass ||
                minMaxSoundTuningDisplay.DEFAULT_BASS_VALUE,
              soundTuningRightTreble:
                baselineFavorite?.soundTuningRightTreble ||
                minMaxSoundTuningDisplay.DEFAULT_TREBLE_VALUE,
              isRightFeedbackEnabled:
                baselineFavorite?.isRightFeedbackEnabled ||
                minMaxSoundTuningDisplay.DEFAULT_FEEDBACK_ENABLED_VALUE,
            })
            break
        }

        return self.setNewDeviceConfiguration(newObj)
      },
      setFeedbackEnabled(key, index, side, value) {
        const newObj = { ...self.newDeviceConfiguration }
        newObj.hearingProfiles[index][key] = value
        const appliedObj = self.applyValuesForFavourites(
          self.newDeviceConfiguration,
          value,
          key,
          side,
          index
        )
        return self.setNewDeviceConfiguration(appliedObj)
      },
      setIsEnabled(key, index) {
        const newObj = { ...self.newDeviceConfiguration }

        if (newObj.hearingProfiles[index].environmentalOffset !== "NONE") {
          self.applyFavoritesValuesToProfile(
            newObj,
            newObj.hearingProfiles[index]?.environmentalOffset,
            index
          )
        }
        Object.assign(newObj.hearingProfiles[index], {
          isEnabled: !newObj.hearingProfiles[index][key],
        })

        self.setCheckHandleSelectInputs(newObj, true)
        return self.setNewDeviceConfiguration(newObj)
      },
      setIsEmpty(index) {
        const newObj = { ...self.newDeviceConfiguration }

        Object.assign(newObj.hearingProfiles[index], {
          isEmpty: false,
        })

        self.setCheckHandleSelectInputs(newObj, true)
        return self.setNewDeviceConfiguration(newObj)
      },
      setFavoritesForTempConfiguration(key, value, index) {
        const tempNewDeviceConfiguration = { ...self.newDeviceConfiguration }
        const { openNotification } = getRoot(self).uiStore

        const checkIfOneFavIsSelected = (newObj) => {
          return newObj.favouriteLeft !== null || newObj.favouriteRight !== null
        }

        const favouriteSide =
          key === `favouriteLeftSlot` ? `favouriteLeft` : `favouriteRight`

        const programBaseSide =
          key === `favouriteLeftSlot` ? `baseProgramLeft` : `baseProgramRight`

        const favouriteIndexSide =
          key === `favouriteLeftSlot`
            ? `favouriteLeftIndex`
            : `favouriteRightIndex`

        if (tempNewDeviceConfiguration[key] === value + 1) {
          tempNewDeviceConfiguration[favouriteSide] = null
          tempNewDeviceConfiguration[key] = null
          tempNewDeviceConfiguration[favouriteIndexSide] = null
          if (
            self.isProgramChanged &&
            !checkIfOneFavIsSelected(tempNewDeviceConfiguration)
          ) {
            return openNotification(
              "You need to have at least one baseline when program was changed",
              "warning"
            )
          }
          self.setCheckHandleSelectInputs(tempNewDeviceConfiguration, true)
          return self.setNewDeviceConfiguration(tempNewDeviceConfiguration)
        } else {
          tempNewDeviceConfiguration[key] = value + 1 //favouriteLeftSlot favouriteRightSlot
          tempNewDeviceConfiguration[favouriteSide] =
            tempNewDeviceConfiguration.hearingProfiles[index][programBaseSide] //baseProgramLeft baseProgramRight
          const favouriteIndex = [
            "PROGRAM_A",
            "PROGRAM_B",
            "PROGRAM_C",
            "PROGRAM_D",
          ].indexOf(
            tempNewDeviceConfiguration.hearingProfiles[index][programBaseSide]
          )
          if (favouriteIndex !== -1) {
            tempNewDeviceConfiguration[favouriteIndexSide] = favouriteIndex + 1 // favouriteRightIndex favouriteLeftIndex
          }

          tempNewDeviceConfiguration.favourites.map((favourite, index) => {
            if (tempNewDeviceConfiguration["favouriteLeftIndex"] === index) {
              favourite.isActive = true
            } else
              favourite.isActive =
                tempNewDeviceConfiguration["favouriteRightIndex"] === index
            return favourite
          })
          tempNewDeviceConfiguration.favouritesAppliedAt = parseInt(
            new Date().getTime() / 1000
          )
        }
        self.setCheckHandleSelectInputs(tempNewDeviceConfiguration, true)
        return self.setNewDeviceConfiguration(tempNewDeviceConfiguration)
      },
      setPresetOffsetForTempConfiguration(key, value, index) {
        const tempNewDeviceConfiguration = { ...self.newDeviceConfiguration }
        const {
          soundTuningLeftVolume: favoriteSoundTuningLeftVolume,
          soundTuningRightVolume: favoriteSoundTuningRightVolume,
          soundTuningLeftBass: favoriteSoundTuningLeftBass,
          soundTuningRightBass: favoriteSoundTuningRightBass,
          soundTuningLeftTreble: favoriteSoundTuningLeftTreble,
          soundTuningRightTreble: favoriteSoundTuningRightTreble,
          isLeftFeedbackEnabled: favoriteIsLeftFeedbackEnabled,
          isRightFeedbackEnabled: favoriteIsRightFeedbackEnabled,
        } =
          tempNewDeviceConfiguration.favourites.filter(
            (favourite) =>
              favourite.program === value && !favourite.isHearingAssessmentRes
          )[0] || {}

        const {
          DEFAULT_VOLUME_VALUE,
          DEFAULT_BASS_VALUE,
          DEFAULT_TREBLE_VALUE,
          DEFAULT_FEEDBACK_ENABLED_VALUE,
        } = minMaxSoundTuningDisplay

        Object.assign(tempNewDeviceConfiguration.hearingProfiles[index], {
          baseProgramLeft: String(value),
          baseProgramRight: String(value),
          environmentalOffset: "NONE",
          isEcaEnabled: true,
          soundTuningLeftVolume:
            favoriteSoundTuningLeftVolume || DEFAULT_VOLUME_VALUE,
          soundTuningRightVolume:
            favoriteSoundTuningRightVolume || DEFAULT_VOLUME_VALUE,
          soundTuningLeftBass:
            favoriteSoundTuningLeftBass || DEFAULT_BASS_VALUE,
          soundTuningRightBass:
            favoriteSoundTuningRightBass || DEFAULT_BASS_VALUE,
          soundTuningLeftTreble:
            favoriteSoundTuningLeftTreble || DEFAULT_TREBLE_VALUE,
          soundTuningRightTreble:
            favoriteSoundTuningRightTreble || DEFAULT_TREBLE_VALUE,
          isLeftFeedbackEnabled:
            favoriteIsLeftFeedbackEnabled || DEFAULT_FEEDBACK_ENABLED_VALUE,
          isRightFeedbackEnabled:
            favoriteIsRightFeedbackEnabled || DEFAULT_FEEDBACK_ENABLED_VALUE,
        })

        //set default NS values on changing preset programs
        const genIndex = ["EARGO_FIVE", "EARGO_SIX"].findIndex(
          (gen) => gen === process.env.REACT_APP_EARGO_VERSION
        )
        switch (value) {
          case "PROGRAM_A":
          case "PROGRAM_B":
            Object.assign(tempNewDeviceConfiguration.hearingProfiles[index], {
              noiseReduction: [10, 10][genIndex],
            })
            break
          case "PROGRAM_C":
          case "PROGRAM_D":
            Object.assign(tempNewDeviceConfiguration.hearingProfiles[index], {
              noiseReduction: [13, 10][genIndex],
            })
            break
          default:
            return
        }

        self.applyForNewConfiguration(tempNewDeviceConfiguration)
        self.applyForNewSoundMatch()
        self.applyForChangesInProfiles(tempNewDeviceConfiguration)
        self.setCheckHandleSelectInputs(tempNewDeviceConfiguration, true)
        return self.setNewDeviceConfiguration(tempNewDeviceConfiguration)
      },
      setEnvOffsetForTempConfiguration(key, value, index) {
        const tempNewDeviceConfiguration = { ...self.newDeviceConfiguration }
        self.applyFavoritesValuesToProfile(
          tempNewDeviceConfiguration,
          value,
          index
        )

        Object.assign(tempNewDeviceConfiguration.hearingProfiles[index], {
          isEcaEnabled: true,
        })
        //set default NS values on changing offsets
        const genIndex = ["EARGO_FIVE", "EARGO_SIX"].findIndex(
          (gen) => gen === process.env.REACT_APP_EARGO_VERSION
        )
        switch (value) {
          case "RESTAURANT":
            Object.assign(tempNewDeviceConfiguration.hearingProfiles[index], {
              noiseReduction: [13, 15][genIndex],
            })
            break
          case "MASK":
          case "MEETING":
            Object.assign(tempNewDeviceConfiguration.hearingProfiles[index], {
              noiseReduction: [13, 10][genIndex],
            })
            break
          case "TV":
            Object.assign(tempNewDeviceConfiguration.hearingProfiles[index], {
              noiseReduction: [10, 10][genIndex],
            })
            break
          case "PHONE":
            Object.assign(tempNewDeviceConfiguration.hearingProfiles[index], {
              noiseReduction: [7, 10][genIndex],
            })
            break
          case "MUSIC":
            Object.assign(tempNewDeviceConfiguration.hearingProfiles[index], {
              noiseReduction: [0, 0][genIndex],
            })
            break
          default:
            return
        }

        self.applyForNewConfiguration(tempNewDeviceConfiguration)
        self.applyForNewSoundMatch()
        self.applyForChangesInProfiles(tempNewDeviceConfiguration)
        self.setCheckHandleSelectInputs(tempNewDeviceConfiguration, true)
        return self.setNewDeviceConfiguration(tempNewDeviceConfiguration)
      },
      handleApplySoundMatchFromHistory() {
        const { tempHearingAssessment } = getRoot(self).hearingEvaluationStore
        const { isEcaEnabledDevice } = getRoot(self).devicesStore

        let tempDeviceConfiguration = { ...self.newDeviceConfiguration }
        const tempHearingProfiles = toJS(
          self.deviceConfiguration.hearingProfilesList
        ).map((profile, index) => {
          if (index === 0) {
            profile.isHearingAssessmentRes = true
            profile.isEmpty = false
            profile.isEnabled = true
            if (isEcaEnabledDevice) {
              profile.isEcaEnabled = true
            }
          } else {
            if (!self.isHearingAssessmentResInAny) {
              profile.isEmpty = true
              profile.isEnabled = false
            }
            profile.isHearingAssessmentRes = false
          }
          profile.isLeftFeedbackEnabled = false
          profile.isRightFeedbackEnabled = false

          return profile
        })

        Object.assign(tempDeviceConfiguration, {
          favouriteLeftIndex: 0,
          favouriteRightIndex: 0,
          favouriteLeft: null,
          favouriteRight: null,
          favouriteLeftSlot: null,
          favouriteRightSlot: null,
          hearingProfiles: tempHearingProfiles,
        })

        const envOffsets = tempHearingAssessment?.environmentalOffsets?.filter(
          (offset) => offset !== "NONE"
        )

        const currentIsEnabled = (index) =>
          self.newDeviceConfiguration.hearingProfiles[index + 1].isEnabled
        //apply environmental offsets if they exist
        if (
          self.profilesInNewConfiguration.length &&
          !self.isHearingAssessmentResInAny
        ) {
          self.profilesInNewConfiguration.forEach((offset, index) => {
            Object.assign(tempDeviceConfiguration.hearingProfiles[index + 1], {
              environmentalOffset: offset,
              isEnabled: currentIsEnabled(index),
              isEmpty: false,
            })
          })
        } else if (!isEmpty(envOffsets) && !self.isHearingAssessmentResInAny) {
          envOffsets.forEach((offset, index) => {
            Object.assign(tempDeviceConfiguration.hearingProfiles[index + 1], {
              environmentalOffset: offset,
              isEnabled: currentIsEnabled(index),
              isEmpty: false,
            })
          })
        }
        self.setCheckHandleSelectInputs(tempDeviceConfiguration, true)
        self.setNewDeviceConfiguration(tempDeviceConfiguration)
      },
      sendConfiguration: flow(function* sendConfiguration() {
        const userId = getRoot(self).sessionStore?.userId
        if (!userId || !self.newDeviceConfiguration) return

        self.startSending()
        self.newDeviceConfiguration.hearingProfiles.forEach(function (
          part,
          index,
          theArray
        ) {
          theArray[index].id = parseInt(theArray[index].id)
        })

        const tempHearingAssessment =
          getRoot(self).hearingEvaluationStore.tempHearingAssessment || null

        const tempHearingProfiles = changeValuesInProfiles(
          self.newDeviceConfiguration.hearingProfiles,
          "post"
        )

        const tempFavourites = changeValuesInProfiles(
          self.newDeviceConfiguration.favourites,
          "post"
        )

        const hearingAssessment = tempHearingAssessment
          ? tempHearingAssessment
          : self.newDeviceConfiguration.hearingAssessment

        const hearingAssessmentLeftAudiogramIndex = getRoot(self)
          .hearingEvaluationStore.tempHearingAssessmentLeftAudiogramIndex
          ? getRoot(self).hearingEvaluationStore
              .tempHearingAssessmentLeftAudiogramIndex
          : self.deviceConfiguration.hearingAssessmentLeftAudiogramIndex

        const hearingAssessmentRightAudiogramIndex = getRoot(self)
          .hearingEvaluationStore.tempHearingAssessmentRightAudiogramIndex
          ? getRoot(self).hearingEvaluationStore
              .tempHearingAssessmentRightAudiogramIndex
          : self.deviceConfiguration.hearingAssessmentRightAudiogramIndex

        let tempConfig = {
          ...self.newDeviceConfiguration,
          hearingProfiles: tempHearingProfiles.slice(0, 4),
          hearingAssessment,
          favourites: tempFavourites,
          hearingAssessmentLeftAudiogramIndex,
          hearingAssessmentRightAudiogramIndex,
        }

        if (self.applyForReset) {
          tempConfig = {
            ...self.newDeviceConfiguration,
            hearingAssessment: null,
            hearingProfiles: tempHearingProfiles,
            favourites: tempFavourites,
          }
        }

        if (getRoot(self).hearingEvaluationStore.isNewSoundMatch) {
          const hearingAssessment = omit(
            getRoot(self).hearingEvaluationStore.newHearingAssessment,
            ["id", "createdAt"]
          )

          yield apiClient.requestManager(
            () =>
              apiClient.post(`${POST_HEARING_ASSESSMENT_PATH_PHP}`, {
                hearingAssessment: hearingAssessment,
              }),
            (response) => {
              const {
                id,
                rootGeneration,
                createdAt,
                updatedAt,
              } = response.data.hearingAssessment
              Object.assign(tempConfig, {
                hearingAssessment: {
                  ...tempConfig.hearingAssessment,
                  id,
                  rootGeneration,
                  createdAt,
                  updatedAt,
                },
              })
            },
            (e) => {
              getRoot(self).uiStore.openNotification(`${e}`, "error")
            }
          )
        }

        yield apiClient.requestManager(
          () =>
            apiClient.post(
              `${POST_CONFIG_API_PATH_PHP}?is_pre_existing=${!!self.checkIfSMIsNewOrChanged}`,
              {
                configuration: tempConfig,
              }
            ),
          () => {
            self.endSending()
            getRoot(self).uiStore.openNotification(
              `Configuration successfully sent`,
              "success"
            )
          },
          (e) => {
            getRoot(self).uiStore.openNotification(`${e}`, "error")
            self.endSending()
          }
        )
      }),
      fetchConfiguration: flow(function* fetch() {
        self.startLoading()
        const { userId } = getRoot(self).sessionStore

        if (!userId) return
        if (self.isMaksEnv && !self.isMaskAdded) {
          self.selectInputs.forEach((input) => {
            input.unshift({
              value: "MASK",
              disabled: false,
            })
          })
        }

        yield apiClient.requestManager(
          () => apiClient.get(`${GET_CONFIG_API_PATH}`, null),
          (response) => {
            const { deviceConfiguration } = response.data
            if (!deviceConfiguration) {
              self.setDefaultConfig(true)
              const camelCaseConfig = mapKeysToCamelCase(DEFAULT_CONFIG)
              self.addDeviceConfiguration(camelCaseConfig.deviceConfiguration)
              camelCaseConfig.deviceConfiguration.hearingProfiles.forEach(
                self.addHearingProfile
              )
              self.addFavourites(camelCaseConfig.deviceConfiguration.favourites)
              return
            }

            if (!deviceConfiguration.hearingProfiles.length) {
              self.setDefaultConfig(true)
              const camelCaseConfig = mapKeysToCamelCase(DEFAULT_CONFIG)
              camelCaseConfig.deviceConfiguration.hearingProfiles.forEach(
                self.addHearingProfile
              )
              self.addFavourites(camelCaseConfig.deviceConfiguration.favourites)
              return
            }

            self.addDeviceConfiguration(deviceConfiguration)
            const tempHearingProfiles = changeValuesInProfiles(
              sortBy(
                response.data.deviceConfiguration.hearingProfiles,
                "position"
              ),
              "fetch"
            )
            tempHearingProfiles.forEach(self.addHearingProfile)

            if (typeof deviceConfiguration.favourites !== undefined) {
              const tempFavourites = changeValuesInProfiles(
                deviceConfiguration?.favourites,
                "fetch"
              )
              self.addFavourites(tempFavourites)
            } else {
              self.addFavourites(TEMP_FAVOURITES_MOCK.favourites)
            }

            self.setDefaultConfig(false)
          },
          (e) => {
            getRoot(self).uiStore.openNotification(`${e}`, "error")
            self.setNotFoundError()
          }
        )
        self.endLoading()
      }),
      addDeviceConfiguration(attributes) {
        const { isNewConfig } = getRoot(self).devicesStore
        let findSlotOfFavouritesLeft,
          findSlotOfFavouritesRight = null
        if (
          attributes.favourites &&
          attributes.favouriteLeftIndex &&
          attributes.favouriteRightIndex &&
          isNewConfig
        ) {
          findSlotOfFavouritesLeft = attributes.hearingProfiles.find(
            (profile) =>
              profile.baseProgramLeft ===
                attributes.favourites[attributes.favouriteLeftIndex].program &&
              profile.baseProgramRight ===
                attributes.favourites[attributes.favouriteLeftIndex].program &&
              profile.environmentalOffset === "NONE"
          )?.position
          findSlotOfFavouritesRight = attributes.hearingProfiles.find(
            (profile) =>
              profile.baseProgramLeft ===
                attributes.favourites[attributes.favouriteRightIndex].program &&
              profile.baseProgramRight ===
                attributes.favourites[attributes.favouriteRightIndex].program &&
              profile.environmentalOffset === "NONE"
          )?.position
        }

        attributes = omit(attributes, [
          "configurationHistories",
          "hearingProfiles",
          "favourites",
        ])

        Object.assign(self.deviceConfiguration, {
          ...attributes,
          favouriteRightSlot: isNewConfig
            ? findSlotOfFavouritesRight
            : attributes.favouriteRightSlot,
          favouriteLeftSlot: isNewConfig
            ? findSlotOfFavouritesLeft
            : attributes.favouriteLeftSlot,
        })
      },
      addFavourites(attributes) {
        self.deviceConfiguration.favourites = []
        attributes.forEach((favorite) =>
          self.deviceConfiguration.favourites.push(favorite)
        )
      },
      addHearingProfile(attributes) {
        attributes.id = String(attributes.id)
        self.deviceConfiguration.hearingProfiles.set(attributes.id, {
          ...attributes,
        })
      },
      addConfigurationHistory(attributes) {
        attributes.id = String(attributes.id)
        self.deviceConfiguration.configurationHistories.set(attributes.id, {
          ...attributes,
        })
      },
      clearDeviceConfigurationValues() {
        self.setIsProgramChanged(null)
        self.setApplyForReset(false)
        self.clearHistoryDeviceConfiguration()
        const tempDeviceConfiguration = toJS(
          self.deviceConfiguration.plainDeviceConfiguration,
          {
            recurseEverything: true,
          }
        )
        self.setNewDeviceConfiguration(tempDeviceConfiguration)
      },
      reset() {
        self.state = INITIAL
        self.deviceConfiguration = {}
      },
    }
  })

export default ConfigurationStore
