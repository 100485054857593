import React, { useState } from "react"
import { AppBar, Tabs, Tab, Box, Drawer, List } from "@material-ui/core"
import styled from "styled-components"
import colors from "styles/colors"
import { observer } from "mobx-react"
import { useUiStore, useLogsStore } from "hooks/stores"
import LogsTile from "./logs_tile/logs_tile"
import DateTimePicker from "./logs_date_picker/logs_date_picker"
import Loading from "../loading/loading"

const StyledTabPanel = styled.div`
  justify-content: center;
  height: 100%;
  background-color: ${colors.white};
  border-left: 1px solid rgba(51, 51, 51, 0.17);
  overflow: auto;

  && {
    & > div {
      padding: 0;
    }
  }
`

const StyledTab = styled(Tab)`
  && {
    text-transform: none;
    color: ${colors.blue};
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
    min-width: 100px;

    &:focus {
      opacity: 1;
    }
  }
`

const StyledTabs = styled(Tabs)`
  && {
    background-color: ${colors.white};

    .MuiTabs-indicator {
      background-color: ${colors.blue};
    }
  }
`

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <StyledTabPanel
      hidden={value !== index}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </StyledTabPanel>
  )
}

const StyledDrawer = styled(Drawer)`
  && {
    .MuiDrawer-paper {
      width: 300px;
      height: calc(100vh - 42px);
      top: 42px;
      padding: 0;
      z-index: 1400;
    }
  }
`
const RightSection = () => {
  const { showLogsDrawer, switchLogsDrawer } = useUiStore()
  const {
    logsList,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    isLoaded,
    logsArray,
    minDate,
  } = useLogsStore()

  const [value, setValue] = useState(0)

  const onChange = (dates) => {
    if (!dates) {
      setStartDate(null)
      setEndDate(null)
      return
    }

    if (dates[0] === null && dates[1] === null) {
      setStartDate(null)
      setEndDate(null)
      return
    }

    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <StyledDrawer
      anchor="right"
      variant="persistent"
      open={showLogsDrawer}
      onClose={switchLogsDrawer}
    >
      <AppBar position="static">
        <StyledTabs value={value} onChange={handleChange} centered>
          <StyledTab label="History" />
          <StyledTab label="User" />
          <StyledTab label="System" />
        </StyledTabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DateTimePicker
          onDatepickerChange={onChange}
          startDate={startDate}
          endDate={endDate}
          disabled={!logsArray.length}
          minDate={
            logsArray.length
              ? new Date(logsArray[logsArray.length - 1]?.createdAt)
              : null
          }
        />
        {!isLoaded ? (
          <Loading fitScreen />
        ) : (
          logsList.map((log, index) => (
            <List component="nav" key={index}>
              <LogsTile index={index} {...log} />
            </List>
          ))
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DateTimePicker
          onDatepickerChange={onChange}
          startDate={startDate}
          endDate={endDate}
          disabled={!logsArray.length}
          minDate={logsArray.length ? minDate : null}
        />
        {!isLoaded ? (
          <Loading fitScreen />
        ) : (
          logsList
            .filter((log) => log.type === "USER")
            .map((log, index) => (
              <List component="nav" key={index}>
                <LogsTile index={index} {...log} />
              </List>
            ))
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DateTimePicker
          onDatepickerChange={onChange}
          startDate={startDate}
          endDate={endDate}
          disabled={!logsArray.length}
          minDate={
            logsArray.length
              ? new Date(logsArray[logsArray.length - 1]?.createdAt)
              : null
          }
        />
        {!isLoaded ? (
          <Loading fitScreen />
        ) : (
          logsList
            .filter((log) => log.type !== "USER")
            .map((log, index) => (
              <List component="nav" key={index}>
                <LogsTile index={index} {...log} />
              </List>
            ))
        )}
      </TabPanel>
    </StyledDrawer>
  )
}

export default observer(RightSection)
