import {
  NormalIcon,
  MusicIcon,
  RestaurantIcon,
  TheaterIcon,
  TvIcon,
  MeetingIcon,
  CrowdIcon,
  PhoneIcon,
  Preset1Icon,
  Preset2Icon,
  Preset3Icon,
  Preset4Icon,
  MaskIcon,
} from "../images/svg"
import React from "react"
import {
  snakeCase,
  camelCase,
  isArray,
  isEmpty,
  sum,
  flattenDeep,
  indexOf,
} from "lodash"
import deepMapKeys from "map-keys-deep-lodash"
import { toJS } from "mobx"

const minMaxSoundTuningDisplay = {
  DEFAULT_VOLUME_VALUE: 0,
  MIN_VOLUME_VALUE: -4,
  MAX_VOLUME_VALUE: 4,
  DEFAULT_BASS_VALUE: 0,
  MIN_BASS_VALUE: -4,
  MAX_BASS_VALUE: 4,
  DEFAULT_TREBLE_VALUE: 0,
  MIN_TREBLE_VALUE: -4,
  MAX_TREBLE_VALUE: 4,
  DEFAULT_FEEDBACK_ENABLED_VALUE: false,
  DEFAULT_FEEDBACK_ENABLED_VALUE_NORMAL: true,
  DEFAULT_ECA_VALUE: true,
}

// Feedback cancellation "notch filter"
const FBC_CORRECTION = [
  0,
  0,
  0,
  0,
  -2,
  -4,
  -5,
  -6,
  -7,
  -7,
  -7,
  -7,
  -7,
  -7,
  -7,
  -7,
  -4,
  -2,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
]

const changeValuesInProfiles = (profiles, type) => {
  const toJSProfiles = toJS(profiles, {
    recurseEverything: true,
  })
  const isFetch = type === "fetch"

  const switchValuesVolume = (value) => {
    const switchArrayValuesVolumeFirst = [0, -2, -4, -6, -8]
    const switchArrayValuesVolumeSecond = [4, 2, 0, -2, -4]
    const indexOfValue = indexOf(
      isFetch ? switchArrayValuesVolumeFirst : switchArrayValuesVolumeSecond,
      value
    )
    if (indexOfValue === -1) {
      return isFetch ? 0 : -4
    }
    return isFetch
      ? switchArrayValuesVolumeSecond[indexOfValue]
      : switchArrayValuesVolumeFirst[indexOfValue]
  }

  const switchValuesTrebleBass = (value) => {
    const switchArrayValuesVolumeFirst = [8, 7, 6, 5, 4]
    const switchArrayValuesVolumeSecond = [4, 2, 0, -2, -4]
    const indexOfValue = indexOf(
      isFetch ? switchArrayValuesVolumeFirst : switchArrayValuesVolumeSecond,
      value
    )
    if (indexOfValue === -1) {
      return isFetch ? 0 : 6
    }
    return isFetch
      ? switchArrayValuesVolumeSecond[indexOfValue]
      : switchArrayValuesVolumeFirst[indexOfValue]
  }

  return toJSProfiles.map((profile) => {
    Object.assign(profile, {
      soundTuningLeftVolume: switchValuesVolume(profile.soundTuningLeftVolume),
      soundTuningRightVolume: switchValuesVolume(
        profile.soundTuningRightVolume
      ),
      soundTuningLeftTreble: switchValuesTrebleBass(
        profile.soundTuningLeftTreble
      ),
      soundTuningRightTreble: switchValuesTrebleBass(
        profile.soundTuningRightTreble
      ),
      soundTuningLeftBass: switchValuesTrebleBass(profile.soundTuningLeftBass),
      soundTuningRightBass: switchValuesTrebleBass(
        profile.soundTuningRightBass
      ),
      isLeftFeedbackEnabled: profile.isLeftFeedbackEnabled,
      isRightFeedbackEnabled: profile.isRightFeedbackEnabled,
      feedbackCorrectionLeft: profile.isLeftFeedbackEnabled
        ? FBC_CORRECTION
        : [],
      feedbackCorrectionRight: profile.isRightFeedbackEnabled
        ? FBC_CORRECTION
        : [],
      isEcaEnabled: profile.isEcaEnabled,
      noiseReduction: profile.noiseReduction,
    })
    return profile
  })
}

const configurationIconPicker = (environmentalOffset) => {
  switch (environmentalOffset) {
    case "NORMAL":
      return <NormalIcon />
    case "RESTAURANT":
      return <RestaurantIcon />
    case "MUSIC":
      return <MusicIcon />
    case "THEATER":
      return <TheaterIcon />
    case "TV":
      return <TvIcon />
    case "PHONE":
      return <PhoneIcon />
    case "MEETING":
      return <MeetingIcon />
    case "CROWD":
      return <CrowdIcon />
    case "MASK":
      return <MaskIcon />
    case "PROGRAM_A":
      return <Preset1Icon />
    case "PROGRAM_B":
      return <Preset2Icon />
    case "PROGRAM_C":
      return <Preset3Icon />
    case "PROGRAM_D":
      return <Preset4Icon />
    default:
      return <NormalIcon />
  }
}

const mapKeysToSnakeCase = (collection) => {
  let parsedData
  if (collection) {
    parsedData = isArray(collection)
      ? collection.map((collection) =>
          deepMapKeys(collection, (v, k) => snakeCase(k))
        )
      : deepMapKeys(collection, (v, k) => snakeCase(k))
  }
  return parsedData
}

const mapKeysToCamelCase = (collection) => {
  let parsedData
  if (collection) {
    parsedData = isArray(collection)
      ? collection.map((collection) =>
          deepMapKeys(collection, (v, k) => camelCase(k))
        )
      : deepMapKeys(collection, (v, k) => camelCase(k))
  }
  return parsedData
}

function arrayMove(arr, fromIndex, toIndex) {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
}

const calculateAverage = (...args) => {
  if (isEmpty(args)) return 0
  const elements = flattenDeep(args)
  return parseFloat((sum(elements) / elements.length).toFixed(2))
}

const findFavouriteInConfiguration = (configuration) => {
  if (configuration.hearingProfiles.length && configuration.favourites.length) {
    let findSlotOfFavouritesLeft, findSlotOfFavouritesRight

    if (configuration.favouriteLeftIndex)
      findSlotOfFavouritesLeft = configuration.hearingProfiles.findIndex(
        (profile) =>
          profile.baseProgramLeft ===
            configuration.favourites[configuration.favouriteLeftIndex]
              ?.program &&
          profile.baseProgramRight ===
            configuration.favourites[configuration.favouriteLeftIndex]
              ?.program &&
          profile.environmentalOffset === "NONE"
      )
    if (configuration.favouriteRightIndex) {
      findSlotOfFavouritesRight = configuration.hearingProfiles.findIndex(
        (profile) =>
          profile.baseProgramLeft ===
            configuration.favourites[configuration.favouriteRightIndex]
              ?.program &&
          profile.baseProgramRight ===
            configuration.favourites[configuration.favouriteRightIndex]
              ?.program &&
          profile.environmentalOffset === "NONE"
      )
    }

    return {
      foundFavouriteLeft: findSlotOfFavouritesLeft,
      foundFavouriteRight: findSlotOfFavouritesRight,
    }
  } else {
    return {
      foundFavouriteLeft: null,
      foundFavouriteRight: null,
    }
  }
}

const FIRST_AUDIOGRAM_COLUMNS = Array.from(
  { length: 55 },
  (_, i) => i + 4
).filter((i) => ![13, 32, 53, 57].includes(i))

const BISGAARD = [
  [4, 10, 10, 10, 15, 15, 15, 20, 20],
  [5, 10, 10, 10, 10, 10, 20, 35, 40],
  [6, 10, 10, 10, 10, 10, 10, 20, 45],
  [7, 10, 10, 15, 20, 25, 30, 40, 50],
  [8, 10, 10, 20, 35, 50, 65, 75, 85],
  [9, 10, 10, 25, 35, 45, 50, 55, 60],
  [10, 10, 15, 20, 30, 30, 30, 35, 30],
  [11, 15, 10, 10, 20, 30, 55, 65, 65],
  [12, 15, 10, 10, 15, 15, 30, 55, 70],
  [14, 15, 20, 35, 50, 55, 60, 65, 65],
  [15, 15, 20, 35, 60, 70, 80, 90, 95],
  [16, 20, 15, 20, 35, 55, 60, 65, 60],
  [17, 20, 15, 20, 35, 55, 80, 95, 95],
  [18, 20, 20, 20, 20, 20, 15, 20, 20],
  [19, 20, 20, 25, 30, 35, 40, 50, 50],
  [20, 20, 20, 25, 35, 40, 55, 65, 75],
  [21, 20, 20, 30, 50, 65, 70, 75, 80],
  [22, 20, 25, 35, 45, 45, 50, 60, 75],
  [23, 20, 30, 40, 45, 45, 45, 50, 60],
  [24, 20, 30, 45, 50, 45, 35, 30, 25],
  [25, 25, 25, 25, 25, 25, 25, 30, 40],
  [26, 25, 35, 60, 75, 75, 75, 80, 85],
  [27, 30, 25, 25, 35, 45, 65, 80, 90],
  [28, 30, 30, 40, 50, 50, 60, 70, 75],
  [29, 30, 35, 40, 50, 55, 65, 75, 90],
  [30, 30, 35, 45, 60, 65, 65, 75, 75],
  [31, 30, 35, 45, 55, 55, 50, 55, 55],
  [33, 30, 45, 60, 65, 65, 60, 65, 65],
  [34, 35, 30, 25, 30, 30, 40, 50, 65],
  [35, 35, 35, 35, 35, 35, 35, 35, 40],
  [36, 35, 35, 40, 45, 50, 55, 60, 70],
  [37, 35, 35, 45, 60, 65, 80, 95, 100],
  [38, 35, 40, 40, 40, 40, 40, 45, 55],
  [39, 40, 40, 40, 35, 25, 20, 20, 25],
  [40, 40, 45, 50, 55, 55, 55, 60, 70],
  [41, 40, 45, 50, 60, 60, 60, 70, 85],
  [42, 40, 55, 65, 70, 70, 70, 80, 95],
  [43, 45, 45, 40, 40, 40, 50, 65, 75],
  [44, 45, 50, 60, 70, 70, 75, 80, 80],
  [45, 50, 45, 50, 60, 60, 60, 70, 70],
  [46, 50, 55, 60, 60, 55, 50, 55, 65],
  [47, 55, 50, 45, 50, 50, 50, 60, 60],
  [48, 55, 50, 50, 50, 50, 60, 75, 85],
  [49, 55, 55, 55, 60, 65, 65, 75, 80],
  [50, 55, 55, 55, 50, 45, 40, 40, 45],
  [51, 55, 55, 60, 65, 70, 70, 75, 95],
  [52, 55, 60, 65, 75, 80, 90, 100, 105],
  [54, 60, 55, 55, 60, 65, 80, 90, 95],
  [55, 60, 65, 65, 65, 65, 60, 65, 70],
  [56, 65, 70, 75, 80, 80, 75, 80, 80],
  [58, 80, 75, 70, 70, 65, 65, 70, 75],
]

//https://stackoverflow.com/questions/6832596/how-to-compare-software-version-number-using-js-only-number/53387532
const compareVersions = (a, b) => {
  let i, diff
  let regExStrip0 = /(\.0+)+$/
  let segmentsA = a.replace(regExStrip0, "").split(".")
  let segmentsB = b.replace(regExStrip0, "").split(".")
  let l = Math.min(segmentsA.length, segmentsB.length)

  for (i = 0; i < l; i++) {
    diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10)
    if (diff) {
      return diff
    }
  }
  return segmentsA.length - segmentsB.length
}

const parseObjectForChecks = (object) => {
  return JSON.stringify(object, (k, v) =>
    v && typeof v === "object" ? v : "" + v
  )
}

const eargoVersionDisplay = () => {
  //default for now)
  switch (process.env.REACT_APP_EARGO_VERSION) {
    case "EARGO_FIVE":
      return "Eargo 5"
    case "EARGO_SIX":
      return "Eargo 6"
    default:
      return "Eargo 5"
  }
}

const DEBUG_ENABLED = process.env.REACT_APP_DEBUG_MODE === "true"

export {
  minMaxSoundTuningDisplay,
  changeValuesInProfiles,
  calculateAverage,
  configurationIconPicker,
  mapKeysToCamelCase,
  mapKeysToSnakeCase,
  arrayMove,
  findFavouriteInConfiguration,
  FIRST_AUDIOGRAM_COLUMNS,
  BISGAARD,
  compareVersions,
  parseObjectForChecks,
  eargoVersionDisplay,
  DEBUG_ENABLED
}
