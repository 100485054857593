import { minMaxSoundTuningDisplay } from "utils/helpers"

const isEargoSix = process.env.REACT_APP_EARGO_VERSION === "EARGO_SIX"
const {
  DEFAULT_VOLUME_VALUE,
  DEFAULT_TREBLE_VALUE,
  DEFAULT_BASS_VALUE,
  DEFAULT_FEEDBACK_ENABLED_VALUE_NORMAL,
  DEFAULT_FEEDBACK_ENABLED_VALUE,
  DEFAULT_ECA_VALUE,
} = minMaxSoundTuningDisplay

const TEMP_FAVOURITES_MOCK = {
  favourites: [
    {
      program: "PROGRAM_A",
      soundTuningLeftVolume: DEFAULT_VOLUME_VALUE,
      soundTuningRightVolume: DEFAULT_VOLUME_VALUE,
      soundTuningLeftTreble: DEFAULT_TREBLE_VALUE,
      soundTuningRightTreble: DEFAULT_TREBLE_VALUE,
      soundTuningLeftBass: DEFAULT_BASS_VALUE,
      soundTuningRightBass: DEFAULT_BASS_VALUE,
      isLeftFeedbackEnabled: DEFAULT_FEEDBACK_ENABLED_VALUE_NORMAL,
      isRightFeedbackEnabled: DEFAULT_FEEDBACK_ENABLED_VALUE_NORMAL,
      isActive: false,
      isHearingAssessmentRes: true,
      appliedAt: parseInt(new Date().getTime() / 1000),
    },
    {
      program: "PROGRAM_A",
      soundTuningLeftVolume: DEFAULT_VOLUME_VALUE,
      soundTuningRightVolume: DEFAULT_VOLUME_VALUE,
      soundTuningLeftTreble: DEFAULT_TREBLE_VALUE,
      soundTuningRightTreble: DEFAULT_TREBLE_VALUE,
      soundTuningLeftBass: DEFAULT_BASS_VALUE,
      soundTuningRightBass: DEFAULT_BASS_VALUE,
      isLeftFeedbackEnabled: DEFAULT_FEEDBACK_ENABLED_VALUE,
      isRightFeedbackEnabled: DEFAULT_FEEDBACK_ENABLED_VALUE,
      isActive: false,
      isHearingAssessmentRes: false,
      appliedAt: parseInt(new Date().getTime() / 1000),
    },
    {
      program: "PROGRAM_B",
      soundTuningLeftVolume: DEFAULT_VOLUME_VALUE,
      soundTuningRightVolume: DEFAULT_VOLUME_VALUE,
      soundTuningLeftTreble: DEFAULT_TREBLE_VALUE,
      soundTuningRightTreble: DEFAULT_TREBLE_VALUE,
      soundTuningLeftBass: DEFAULT_BASS_VALUE,
      soundTuningRightBass: DEFAULT_BASS_VALUE,
      isLeftFeedbackEnabled: DEFAULT_FEEDBACK_ENABLED_VALUE,
      isRightFeedbackEnabled: DEFAULT_FEEDBACK_ENABLED_VALUE,
      isActive: false,
      isHearingAssessmentRes: false,
      appliedAt: parseInt(new Date().getTime() / 1000),
    },
    {
      program: "PROGRAM_C",
      soundTuningLeftVolume: DEFAULT_VOLUME_VALUE,
      soundTuningRightVolume: DEFAULT_VOLUME_VALUE,
      soundTuningLeftTreble: DEFAULT_TREBLE_VALUE,
      soundTuningRightTreble: DEFAULT_TREBLE_VALUE,
      soundTuningLeftBass: DEFAULT_BASS_VALUE,
      soundTuningRightBass: DEFAULT_BASS_VALUE,
      isLeftFeedbackEnabled: DEFAULT_FEEDBACK_ENABLED_VALUE,
      isRightFeedbackEnabled: DEFAULT_FEEDBACK_ENABLED_VALUE,
      isActive: false,
      isHearingAssessmentRes: false,
      appliedAt: parseInt(new Date().getTime() / 1000),
    },
    {
      program: "PROGRAM_D",
      soundTuningLeftVolume: DEFAULT_VOLUME_VALUE,
      soundTuningRightVolume: DEFAULT_VOLUME_VALUE,
      soundTuningLeftTreble: DEFAULT_TREBLE_VALUE,
      soundTuningRightTreble: DEFAULT_TREBLE_VALUE,
      soundTuningLeftBass: DEFAULT_BASS_VALUE,
      soundTuningRightBass: DEFAULT_BASS_VALUE,
      isLeftFeedbackEnabled: DEFAULT_FEEDBACK_ENABLED_VALUE,
      isRightFeedbackEnabled: DEFAULT_FEEDBACK_ENABLED_VALUE,
      isActive: false,
      isHearingAssessmentRes: false,
      appliedAt: parseInt(new Date().getTime() / 1000),
    },
  ],
}

// Mocked config
const DEFAULT_CONFIG = {
  changelog: [],
  device_configuration: {
    favourite_left_index: null,
    favourite_right_index: null,
    favourite_left: null,
    favourite_right: null,
    favourite_left_slot: null,
    favourite_right_slot: null,
    is_asymmetrical_hl_checked: false,
    hearing_assessment_left_audiogram_index: 0,
    hearing_assessment_right_audiogram_index: 0,
    favourites_applied_at: null,
    ha_applied_at: null,
    ha_id: null,
    hearing_profiles: [
      {
        id: 1,
        base_program_left: "PROGRAM_A",
        base_program_right: "PROGRAM_A",
        environmental_offset: "NONE",
        is_hearing_assessment_res: false,
        is_empty: false,
        is_enabled: true,
        sound_tuning_left_volume: DEFAULT_VOLUME_VALUE,
        sound_tuning_right_volume: DEFAULT_VOLUME_VALUE,
        sound_tuning_left_treble: DEFAULT_TREBLE_VALUE,
        sound_tuning_right_treble: DEFAULT_TREBLE_VALUE,
        sound_tuning_left_bass: DEFAULT_BASS_VALUE,
        sound_tuning_right_bass: DEFAULT_BASS_VALUE,
        noise_reduction: 10,
        is_left_feedback_enabled: DEFAULT_FEEDBACK_ENABLED_VALUE,
        is_right_feedback_enabled: DEFAULT_FEEDBACK_ENABLED_VALUE,
        is_eca_enabled: DEFAULT_ECA_VALUE,
        position: 1,
        applied_at: parseInt(new Date().getTime() / 1000),
      },
      {
        id: 2,
        base_program_left: "PROGRAM_B",
        base_program_right: "PROGRAM_B",
        environmental_offset: "NONE",
        is_hearing_assessment_res: false,
        is_empty: false,
        is_enabled: true,
        sound_tuning_left_volume: DEFAULT_VOLUME_VALUE,
        sound_tuning_right_volume: DEFAULT_VOLUME_VALUE,
        sound_tuning_left_treble: DEFAULT_TREBLE_VALUE,
        sound_tuning_right_treble: DEFAULT_TREBLE_VALUE,
        sound_tuning_left_bass: DEFAULT_BASS_VALUE,
        sound_tuning_right_bass: DEFAULT_BASS_VALUE,
        noise_reduction: 10,
        is_left_feedback_enabled: DEFAULT_FEEDBACK_ENABLED_VALUE,
        is_right_feedback_enabled: DEFAULT_FEEDBACK_ENABLED_VALUE,
        is_eca_enabled: DEFAULT_ECA_VALUE,
        position: 2,
        applied_at: parseInt(new Date().getTime() / 1000),
      },
      {
        id: 3,
        base_program_left: "PROGRAM_C",
        base_program_right: "PROGRAM_C",
        environmental_offset: "NONE",
        is_hearing_assessment_res: false,
        is_empty: false,
        is_enabled: true,
        sound_tuning_left_volume: DEFAULT_VOLUME_VALUE,
        sound_tuning_right_volume: DEFAULT_VOLUME_VALUE,
        sound_tuning_left_treble: DEFAULT_TREBLE_VALUE,
        sound_tuning_right_treble: DEFAULT_TREBLE_VALUE,
        sound_tuning_left_bass: DEFAULT_BASS_VALUE,
        sound_tuning_right_bass: DEFAULT_BASS_VALUE,
        noise_reduction: isEargoSix ? 10 : 13,
        is_left_feedback_enabled: DEFAULT_FEEDBACK_ENABLED_VALUE,
        is_right_feedback_enabled: DEFAULT_FEEDBACK_ENABLED_VALUE,
        is_eca_enabled: DEFAULT_ECA_VALUE,
        position: 3,
        applied_at: parseInt(new Date().getTime() / 1000),
      },
      {
        id: 4,
        base_program_left: "PROGRAM_D",
        base_program_right: "PROGRAM_D",
        environmental_offset: "NONE",
        is_hearing_assessment_res: false,
        is_empty: false,
        is_enabled: true,
        sound_tuning_left_volume: DEFAULT_VOLUME_VALUE,
        sound_tuning_right_volume: DEFAULT_VOLUME_VALUE,
        sound_tuning_left_treble: DEFAULT_TREBLE_VALUE,
        sound_tuning_right_treble: DEFAULT_TREBLE_VALUE,
        sound_tuning_left_bass: DEFAULT_BASS_VALUE,
        sound_tuning_right_bass: DEFAULT_BASS_VALUE,
        noise_reduction: isEargoSix ? 10 : 13,
        is_left_feedback_enabled: DEFAULT_FEEDBACK_ENABLED_VALUE,
        is_right_feedback_enabled: DEFAULT_FEEDBACK_ENABLED_VALUE,
        is_eca_enabled: DEFAULT_ECA_VALUE,
        position: 4,
        applied_at: parseInt(new Date().getTime() / 1000),
      },
    ],
    favourites: TEMP_FAVOURITES_MOCK.favourites,
  },
}

export { DEFAULT_CONFIG, TEMP_FAVOURITES_MOCK }
