import React, { useCallback, useEffect } from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import colors from "styles/colors"
import Router from "router/router"
import {
  useConfigurationStore,
  useDevicesStore,
  useHearingEvaluationStore,
  useJournalStore,
  useLogsStore,
  useSessionStore,
  useSocketStore,
  useStatisticsStore,
  useUiStore,
} from "../hooks/stores"
import { observer } from "mobx-react"

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    font-family: Inter;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${colors.white};
    font-style: normal;
  }
  
  ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
      background: #fff;
      opacity: 0.7;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: darkgray;
    border-radius: 10px;
  }
`

function App() {
  const { openNotification } = useUiStore()
  const {
    fetch: sessionStoreFetch,
    userId,
    isLoaded,
    canFetchOtherData,
    checkIfOnSalesforcePage,
  } = useSessionStore()
  const {
    fetchConfiguration,
    reset: resetConfiguration,
  } = useConfigurationStore()
  const { initializeSocket } = useSocketStore()
  const { fetchJournal } = useJournalStore()
  const { fetchLogs, reset: resetLogs, initSocketLogs } = useLogsStore()
  const { reset: resetStatistics } = useStatisticsStore()
  const { fetchDevices, reset: resetDevices } = useDevicesStore()
  const {
    fetchHearingEvaluation,
    reset: resetHearingEvaluation,
  } = useHearingEvaluationStore()

  // should be moved to separate store that initialize all
  useEffect(() => {
    if (checkIfOnSalesforcePage) {
      return openNotification(
        "You can't use PHP Portal outside salesforce window",
        "error"
      )
    }
    sessionStoreFetch()
  }, [sessionStoreFetch])

  const onChangingSelect = useCallback(async () => {
    if (canFetchOtherData) {
      initializeSocket()
      initSocketLogs()
      resetLogs()
      resetConfiguration()
      resetHearingEvaluation()
      resetStatistics()
      resetDevices()

      await fetchDevices() // need to fetch Devices early
      await fetchConfiguration()
      await fetchHearingEvaluation()
      await fetchJournal()
      await fetchLogs()
    }
  }, [userId, canFetchOtherData])

  useEffect(() => {
    if (isLoaded) {
      onChangingSelect()
    }
  }, [onChangingSelect, isLoaded])

  return (
    <>
      <ThemeProvider theme={{ colors }}>
        <Router />
      </ThemeProvider>
      <GlobalStyle />
    </>
  )
}

export default observer(App)
