import { types } from "mobx-state-tree"
import Charger from "./charger/charger"
import Hi from "./hi/hi"

const FirmwareVersions = types
  .model("FirmwareVersions", {
    charger: types.optional(types.array(Charger), []),
    hi: types.optional(types.array(Hi), []),
  })
  .views((self) => {
    return {
      convertVersionToDisplayVersion(deviceType, version) {
        const findVersion = self[deviceType].find(
          (item) => item.version === version
        )
        if (!version) return "N/A"
        return findVersion?.displayVersion || version
      },
    }
  })

export default FirmwareVersions
